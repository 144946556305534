import V0Icon from "@/assets/img/liff/level-system/v0.webp";
import V1Icon from "@/assets/img/liff/level-system/v1.webp";
import V2Icon from "@/assets/img/liff/level-system/v2.webp";
import V3Icon from "@/assets/img/liff/level-system/v3.webp";
import V4Icon from "@/assets/img/liff/level-system/v4.webp";
import PointV0 from "@/assets/img/liff/level-system/point_v0.webp";
import PointV1 from "@/assets/img/liff/level-system/point_v1.webp";
import PointV2 from "@/assets/img/liff/level-system/point_v2.webp";
import PointV3 from "@/assets/img/liff/level-system/point_v3.webp";
import PointV4 from "@/assets/img/liff/level-system/point_v4.webp";
import QRV0 from "@/assets/img/liff/level-system/qr_v0.webp";
import QRV1 from "@/assets/img/liff/level-system/qr_v1.webp";
import QRV2 from "@/assets/img/liff/level-system/qr_v2.webp";
import QRV3 from "@/assets/img/liff/level-system/qr_v3.webp";
import QRV4 from "@/assets/img/liff/level-system/qr_v4.webp";
import UpgradePopupV0 from "@/assets/img/liff/level-system/upgrade_popup_v0.png";
import UpgradePopupV1 from "@/assets/img/liff/level-system/upgrade_popup_v1.png";
import UpgradePopupV2 from "@/assets/img/liff/level-system/upgrade_popup_v2.png";
import UpgradePopupV3 from "@/assets/img/liff/level-system/upgrade_popup_v3.png";
import UpgradePopupV4 from "@/assets/img/liff/level-system/upgrade_popup_v4.png";
import MemberBenefitV0 from "@/assets/img/liff/level-system/member_benefit_v0.png";
import MemberBenefitV1 from "@/assets/img/liff/level-system/member_benefit_v1.png";
import MemberBenefitV2 from "@/assets/img/liff/level-system/member_benefit_v2.png";
import MemberBenefitV3 from "@/assets/img/liff/level-system/member_benefit_v3.png";
import MemberBenefitV4 from "@/assets/img/liff/level-system/member_benefit_v4.png";

class TierStyle {
	constructor(tierInfo) {
		if (!tierInfo) return;
		this.tierName = tierInfo.tierName;
		this.tierNameEN = tierInfo.tierNameEN;
		this.tierCode = tierInfo.tierCode;
		this.tierSeq = tierInfo.tierSeq;
		this.totalAmount = tierInfo.totalAmount;
		this.nextTierThreshold = tierInfo.nextTierThreshold;
		this.vipIcon = tierInfo.vipIcon;
		this.pointIcon = tierInfo.pointIcon;
		this.qrIcon = tierInfo.qrIcon;
		this.color = tierInfo.color;
		this.upgradePopup = tierInfo.upgradePopup;
		this.memberBenefit = tierInfo.memberBenefit;
	}

	getTierStyleByCode(tierCode) {
		const tierStylesArray = [V0, V1, V2, V3, V4];
		const tier = tierStylesArray.find(
			(tierStyle) => tierStyle.tierCode === tierCode,
		);
		return tier ? tier : null;
	}

	getTierRedeemableListByCode(tierCode) {
		// 透過 tierCode 取得對應的 TierStyle 物件，接著用seq判斷找出所有大於等於該seq的TierStyle物件
		const tierStylesArray = [V0, V1, V2, V3, V4];
		const tier = tierStylesArray.find(
			(tierStyle) => tierStyle.tierCode === tierCode,
		);
		const tierSeq = tier ? tier.tierSeq : 0;
		const tierRedeemableList = tierStylesArray.filter(
			(tierStyle) => tierStyle.tierSeq >= tierSeq,
		);
		return tierRedeemableList;
	}
}

export { TierStyle };

/* 探索客 */
export const V0 = new TierStyle({
	tierName: "探索客",
	tierNameEN: "Explorer",
	tierCode: "EX",
	tierSeq: 0,
	totalAmount: 0,
	nextTierThreshold: 0,
	vipIcon: V0Icon,
	pointIcon: PointV0,
	qrIcon: QRV0,
	color: "#FAB242",
	upgradePopup: UpgradePopupV0,
	memberBenefit: MemberBenefitV0,
});

/* 開拓者 */
export const V1 = new TierStyle({
	tierName: "開拓者",
	tierNameEN: "Blazer",
	tierCode: "BL",
	tierSeq: 1,
	totalAmount: 0,
	nextTierThreshold: 20000, // 20000 - 0
	vipIcon: V1Icon,
	pointIcon: PointV1,
	qrIcon: QRV1,
	color: "#185449",
	upgradePopup: UpgradePopupV1,
	memberBenefit: MemberBenefitV1,
});

/* 冒險家 */
export const V2 = new TierStyle({
	tierName: "冒險家",
	tierNameEN: "Adventurer",
	tierCode: "AT",
	tierSeq: 2,
	totalAmount: 20000,
	nextTierThreshold: 18000, // 38000 - 20000
	vipIcon: V2Icon,
	pointIcon: PointV2,
	qrIcon: QRV2,
	color: "#1E3D89",
	upgradePopup: UpgradePopupV2,
	memberBenefit: MemberBenefitV2,
});

/* 巔峰客 */
export const V3 = new TierStyle({
	tierName: "巔峰客",
	tierNameEN: "Pioneer",
	tierCode: "PI",
	tierSeq: 3,
	totalAmount: 38000,
	nextTierThreshold: 82000, // 120000 - 38000
	vipIcon: V3Icon,
	pointIcon: PointV3,
	qrIcon: QRV3,
	color: "#513A78",
	upgradePopup: UpgradePopupV3,
	memberBenefit: MemberBenefitV3,
});

/* 巔峰摯友 */
export const V4 = new TierStyle({
	tierName: "巔峰摯友",
	tierNameEN: "Team Summit",
	tierCode: "TS",
	tierSeq: 4,
	totalAmount: 120000,
	nextTierThreshold: 0,
	vipIcon: V4Icon,
	pointIcon: PointV4,
	qrIcon: QRV4,
	color: "#BB1A2C",
	upgradePopup: UpgradePopupV4,
	memberBenefit: MemberBenefitV4,
});
