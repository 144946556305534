import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import {
	Form,
	Input,
	PhoneNumberField,
	Checkbox,
	Button,
	Select,
} from "@/atoms";
import {
	Popup,
} from "@/components";
import { AreaData, ZipCodes } from "@/apps/liff/pages/redemptionIntroPage/data/index";
import { AppError, LiffContext, api } from "@/utils";

const GiftDeliveryPage = () => {
	const navigate = useNavigate();
	const [couponCode, setCouponCode] = useState('');
	const [giftCard, setGiftCard] = useState('');
	const [couponHash, setCouponHash] = useState('');

	const [districtOptions, setDistrictOptions] = useState([]);
	// 檢查表單是否有效
	const [isFormValid, setIsFormValid] = useState(false);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	// 儲存後端回傳的收件人資訊
	const [userAddressData, setUserAddressData] = useState({
		receiveName: null,
		city: null,
		district: null,
		receiveAddress: null,
		receiveMobile: null,
	});

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	// 送出表單彈窗
	const submitPopup = useRef(null);
	// 點數扣除失敗彈窗
	const pointDeductionFailedPopup = useRef(null);
	// 收件人資訊表單
	const deliverForm = useRef(null);

	const getUserAddress = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/get_user_address");
			const userAddress = res.data;

			let city = "";
			let district = "";
			let address = "";
			if (userAddress.receive_address) {
				const parts = userAddress?.receive_address.split(" ");
				city = parts[1];
				district = parts[2];
				address = parts[3];
			}

			if (userAddress) {
				setUserAddressData({
					receiveName: userAddress?.receive_name,
					city,
					district,
					receiveAddress: address,
					receiveMobile: `${userAddress?.receive_mobile}`,
				});

				// 獲取區域下拉選單資料
				city && getDistrictOptions(city, district);
			}
		} catch (error) {
			console.error("Get User Address Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 獲取縣市下拉選單資料
	const cityOptions = AreaData.map((item) => ({
		key: item.city,
		value: item.city,
	}));

	// 獲取區域下拉選單資料
	const getDistrictOptions = (city, district) => {
		const districtOptions = AreaData.find(
			(item) => item.city === city,
		)?.district?.map((item) => ({
			key: item,
			value: item,
		}));

		// 獲取區域下拉選單資料
		setDistrictOptions(districtOptions);

		// 如果已選擇的區域不在新的區域選項中，清空已選擇區域下拉選單
		if (!districtOptions.find((item) => item.key === district)) {
			setUserAddressData((pre) => ({
				...pre,
				district: null,
			}));
			deliverForm.current.setValue("district", null);
		}
	};

	// 送出兌換資料
	const createRedeemGift = async (formValues) => {
		setIsBtnLoading(true);
		try {
			const res = await api(1).post("/consume_coupon", formValues);
			if (res.data) {
				submitPopup.current.showPopup();
			}
		} catch (error) {
			console.error("Create Exchange Product Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.CRM_COUPON_CONSUME_FAILED:
						toast.error("很抱歉，此優惠券兌換失敗。");
						break;
					case AppError.CRM_COUPON_INVALID_DATE:
						toast.error("很抱歉，此優惠券兌換日期有誤。");
						break;
					case AppError.CRM_COUPON_ALREADY_CONSUMED:
						toast.error("很抱歉，此優惠券已兌換過。");
						break;
					case AppError.CRM_COUPON_EXPIRED:
						toast.error("很抱歉，此優惠券已過期。");
						break;
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			setIsBtnLoading(false);
		}
	};

	const onSubmit = (data) => {
		// 獲取郵遞區號
		const zipCode = ZipCodes[data.city]?.[data.district];
		const formValues = {
			couponCode: couponCode,
			giftCard: giftCard,
			hash: couponHash,
			receiveName: data.receiveName,
			receiveAddress: `${zipCode} ${data.city} ${data.district} ${data.receiveAddress}`,
			receiveMobile: data.receiveMobile,
			isSave: data.isSave,
		};
		createRedeemGift(formValues);
	};

	const handlePopupClose = () => {
		navigate("/coupon");
	};

	const onCityChange = (e) => {
		const city = e.target.value;

		// 更新city欄位值
		deliverForm.current.setValue("city", city);

		setUserAddressData((pre) => ({
			...pre,
			city: city,
		}));

		// 獲取區域下拉選單資料
		getDistrictOptions(city, userAddressData.district);
	};

	useEffect(() => {
		// The query string must have couponCode, giftCard, hash
		const searchParams = new URLSearchParams(window.location.search);
		if (!searchParams.get("couponCode") || !searchParams.get("giftCard") || !searchParams.get("hash")) {
			navigate("/coupon");
		}
		setCouponCode(searchParams.get("couponCode"));
		setGiftCard(searchParams.get("giftCard"));
		setCouponHash(searchParams.get("hash"));

		getUserAddress();
	}, []);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<div className="mb-[75px] w-full space-y-2 bg-white p-5">
						<Form
							onSubmit={onSubmit}
							defaultValues={userAddressData}
							ref={deliverForm}
							onValidChange={setIsFormValid}
						>
							<div className="space-y-4">
								{/* Form */}
								<div>
									<span className="font-medium">請輸入收件人資訊</span>
								</div>

								{/* 姓名 */}
								<div>
									<Input
										name="receiveName"
										label="收件人"
										type="text"
										required={true}
										placeholder="請輸入收件人姓名"
										minLength={1}
										maxLength={100}
									></Input>
								</div>

								{/* 手機 */}
								<div>
									<PhoneNumberField
										name="receiveMobile"
										label="手機"
										type="tel"
										required={true}
										placeholder="請輸入手機號碼"
										requiredMark={true}
									></PhoneNumberField>
								</div>

								{/* 收件地址 */}
								<div>
									<div className="mb-2.5 block text-sm font-medium text-[#333333]">
										收件地址
										<span className="text-red-600">*</span>
									</div>
									{/* 縣市下拉選單 */}
									<div className="mb-4 flex gap-4">
										<div className="w-1/2">
											<Select
												name="city"
												required={true}
												icon="ArrowDropDown"
												placeholder="請選擇縣市"
												selectOptions={cityOptions}
												onChange={(e) => onCityChange(e)}
											></Select>
										</div>
										<div className="w-1/2">
											<Select
												name="district"
												required={true}
												icon="ArrowDropDown"
												placeholder="請選擇區域"
												selectOptions={districtOptions}
											></Select>
										</div>
									</div>
									{/* 地址input */}
									<Input
										name="receiveAddress"
										type="text"
										required={true}
										errMsg="請填寫收件地址"
										minLength={1}
										maxLength={200}
									></Input>
								</div>

								{/* 儲存為預設收件人資訊 */}
								<div>
									<Checkbox name="isSave" required={false}>
										<span className="ml-3 block text-sm">
											儲存為預設收件人資訊
										</span>
									</Checkbox>
								</div>

								<div>
									<Button
										type="submit"
										text="確認送出"
										theme="default"
										color="black"
										disabled={!isFormValid}
										showBtnLoader={isBtnLoading}
									></Button>
								</div>
							</div>
						</Form>
					</div>

					{/* Success Popup */}
					<Popup ref={submitPopup} onClose={handlePopupClose}>
						<div className="flex justify-center">
							<span className="text-center">
								提醒您：
								<br />
								兌換後無法更改收件資料。
							</span>
						</div>
					</Popup>

					{/* Failed Popup */}
					<Popup ref={pointDeductionFailedPopup}>
						<div className="flex flex-col items-center">
							<span className="pb-6 text-xl font-medium">很抱歉</span>
							<span className="text-center">點數扣除失敗，請聯繫客服。</span>
						</div>
					</Popup>
				</>
			)}
		</>
	);
};

export default GiftDeliveryPage;
