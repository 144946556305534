import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import classNames from "classnames";
import toast from "react-hot-toast";

import { Button, Icon } from "@/atoms";
import { Breadcrumbs } from "@/components";
import { api } from "@/utils";

import TnfLogoWhite from "@/assets/img/admin/tnf-logo-light.svg";

const AdminLayout = () => {
	// sideBar是否收起
	const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [currentPath, setCurrentPath] = useState(null);
	// 菜單
	const [menuList, setMenuList] = useState([
		{ key: "productList", value: "商品列表", isOpen: false },
		{ key: "exchangeList", value: "點數商城兌換紀錄", isOpen: false },
		{ key: "couponConsumeList", value: "會員贈品兌換紀錄", isOpen: false },
		{
			key: "displayManagement",
			value: "顯示管理",
			isOpen: false,
			children: [
				{ key: "imgCarouselList", value: "圖片輪播" },
				{ key: "textCarouselList", value: "文字輪播" },
				{ key: "popupList", value: "彈窗" },
			],
		},
	]);
	const location = useLocation();
	const navigate = useNavigate();

	// 登出api
	const adminLogout = async () => {
		try {
			await api().post("/admin/logout");
			localStorage.removeItem("api_token");
			navigate("/admin");
		} catch (error) {
			console.error("Logout Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				console.log(errorCode);
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	useEffect(() => {
		const path = location.pathname.split("/").filter(Boolean);
		const specialActions = ["edit", "add"];
		const actionIndex = path.findIndex((segment) =>
			specialActions.includes(segment),
		);

		if (actionIndex !== -1) {
			// 當前為編輯或新增頁面，將路徑設為上一層
			setCurrentPath(path[actionIndex - 1]);
		} else {
			setCurrentPath(path.length === 2 ? path[1] : path[2]);
		}
	}, [location]);

	return (
		<>
			<div className="flex h-screen flex-col">
				{/* Header */}
				<header className="flex w-full bg-gray-900 py-2 text-sm">
					<nav className="w-full">
						<div className="flex items-center">
							<div className="grid w-64 grid-cols-3 px-6">
								<div
									className="flex cursor-pointer items-center"
									onClick={() => setIsSideBarCollapsed(!isSideBarCollapsed)}
								>
									<div
										className={classNames("duration-200", {
											"rotate-0": isSideBarCollapsed,
											"rotate-180": !isSideBarCollapsed,
										})}
									>
										<Icon iconName={"Menu"} className="text-white"></Icon>
									</div>
								</div>
								<img src={TnfLogoWhite} alt="Tnf logo" />
							</div>

							<div className="flex flex-1 items-center justify-between px-6">
								{/* Breadcrumbs */}
								<Breadcrumbs></Breadcrumbs>

								<div className="hidden lg:block">
									<Button
										type="button"
										theme="admin"
										color="white"
										widthFull={false}
										size="sm"
										onClick={() => adminLogout()}
									>
										<Icon iconName={"Logout"}></Icon>
										<span>登出</span>
									</Button>
								</div>
							</div>
						</div>
					</nav>
				</header>

				<div className="flex flex-1 overflow-y-auto bg-slate-50">
					{/* SideBar */}
					<div
						className={classNames(
							"absolute z-30 flex h-full w-full min-w-[256px] transform flex-col justify-between rounded-xl border-2 border-slate-50 bg-white shadow-sm transition-all duration-300 lg:relative lg:w-64",
							{
								"-translate-x-full lg:translate-x-0": !isSideBarCollapsed, // 展開
								"translate-x-0 lg:-translate-x-full": isSideBarCollapsed, // 收起
							},
						)}
					>
						<nav className="mt-4 flex w-full cursor-pointer flex-col flex-wrap p-6">
							<ul className="space-y-3">
								{menuList.map((item) => (
									<li key={item.key}>
										<div
											className={classNames(
												"flex items-center justify-between rounded-lg px-2.5 py-2 text-blackT80 hover:bg-gray-100",
												{
													"bg-gray-100 font-medium": currentPath === item.key,
												},
												{
													"font-medium text-blue-600": item.isOpen,
												},
											)}
											onClick={() => {
												if (item.children) {
													const newMenuList = menuList.map((menu) =>
														menu.key === item.key
															? { ...menu, isOpen: !menu.isOpen }
															: menu,
													);

													setMenuList(newMenuList);
												} else {
													navigate(`/admin/${item.key}`);
												}
											}}
										>
											{item.value}
											{/* 向下箭頭icon */}
											{item?.children && (
												<div
													className={classNames({
														"rotate-180": item.isOpen,
														"rotate-0": !item.isOpen,
													})}
												>
													<Icon iconName={"KeyboardArrowDown"}></Icon>
												</div>
											)}
										</div>

										{/* 子菜單 */}
										<div
											className={classNames(
												"w-full overflow-hidden transition-[height] duration-300 ease-in-out",
												{
													"h-0": !item.isOpen,
													"h-auto": item.isOpen,
												},
											)}
										>
											{item?.children && (
												<ul className="space-y-3 ps-2 pt-2">
													{item?.children?.map((child) => (
														<li key={child.key}>
															<div
																className={classNames(
																	"flex items-center gap-x-3.5 rounded-lg px-2.5 py-2 text-sm text-blackT80 before:h-1 before:w-1 before:rounded-full before:bg-blackT80 hover:bg-gray-100",
																	{
																		"bg-gray-100 font-medium":
																			currentPath === child.key,
																	},
																)}
																onClick={() =>
																	navigate(`/admin/${item.key}/${child.key}`)
																}
															>
																{child.value}
															</div>
														</li>
													))}
												</ul>
											)}
										</div>
									</li>
								))}
							</ul>
						</nav>

						{/* Logout */}
						<div
							className="flex h-14 items-center justify-center border-t border-gray-200 px-6 lg:hidden"
							onClick={() => adminLogout()}
						>
							<Icon iconName={"Logout"}></Icon>
							<span>登出</span>
						</div>
					</div>

					{/* Content */}
					<div
						className={classNames("w-full overflow-y-auto p-10 duration-300", {
							"lg:ml-0 lg:w-sideBar": !isSideBarCollapsed, // 展開
							"lg:-ml-64": isSideBarCollapsed, // 收起
						})}
					>
						<Outlet></Outlet>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminLayout;
