import { useContext, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { LiffContext, api } from "@/utils";
import { Coupon } from "./Coupon";
import Background from "@/assets/img/liff/coupons/background.png";
import CouponWhite from "@/assets/img/liff/coupons/coupon_white.png";
import CouponBlack from "@/assets/img/liff/coupons/coupon_black.png";
import CouponRed from "@/assets/img/liff/coupons/coupon_red.png";
import CouponYellow from "@/assets/img/liff/coupons/coupon_yellow.png";
import "./index.sass";

class CouponState {
	static UNUSED = 100000001;
	static USED = 100000002;
	static EXPIRED = 100000003;
}

class CouponType {
	static T = 100000000;
	static P = 100000001;
}

const defaultCouponInfo = {
	offline: {
		total: 0,
		unusedCouponCount: 0,
		usedCouponCount: 0,
		expiredCouponCount: 0,
	},
	online: {
		total: 0,
		unusedCouponCount: 0,
		usedCouponCount: 0,
		expiredCouponCount: 0,
	},
};

const CouponPage = () => {
	const location = useLocation();
	const { liffActions } = useContext(LiffContext);
	const [couponList, setCouponList] = useState([]);
	const [modeFilter, setModeFilter] = useState("offline");
	const [stateFilter, setStateFilter] = useState(CouponState.UNUSED);
	const [couponInfo, setCouponInfo] = useState(defaultCouponInfo);
	const filteredCouponList = couponList.filter((coupon) => {
		return coupon.matchMode === modeFilter && coupon.state === stateFilter;
	});

	const couponTypeMap = {
		// key 為 coupon.useChannel
		100000000: {
			mode: "offline",
			type: CouponType.T,
			textColor: "black",
			img: CouponWhite,
		},
		100000001: {
			mode: "offline",
			type: CouponType.T,
			textColor: "white",
			img: CouponBlack,
		},
		100000002: {
			mode: "offline",
			type: CouponType.T,
			textColor: "white",
			img: CouponRed,
		},
		100000007: {
			mode: "online",
			type: CouponType.T,
			textColor: "black",
			img: CouponWhite,
		},
	};

	const getCouponList = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/get_crm_member_coupon_list", {
				params: { page: 1, pageSize: 100 },
			});
			const couponList = res.data.rows;
			classifyCoupons(couponList);
		} catch (error) {
			console.error("Get Coupon List Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	const classifyCoupons = (coupons) => {
		// 深拷貝 defaultCouponInfo
		const couponInfo = JSON.parse(JSON.stringify(defaultCouponInfo));

		const couponData = coupons.map((coupon) => {
			const matchChannel = couponTypeMap[coupon.useChannel];
			const matchType = matchChannel?.type === coupon.couponType;

			if (!matchChannel || !matchType) {
				return {
					...coupon,
					matchMode: null,
					textColor: null,
					image: null,
				};
			}

			couponInfo[matchChannel.mode].total += 1;
			couponInfo[matchChannel.mode][
				`${
					coupon.state === CouponState.UNUSED
						? "unusedCouponCount"
						: coupon.state === CouponState.USED
							? "usedCouponCount"
							: coupon.state === CouponState.EXPIRED
								? "expiredCouponCount"
								: ""
				}`
			] += 1;

			// Special case for gift card
			const textColor = coupon.giftCard ? "white" : matchChannel.textColor;
			const bgImg = coupon.giftCard ? CouponYellow : matchChannel.img;

			return {
				...coupon,
				matchMode: matchChannel.mode,
				textColor: textColor,
				image: bgImg,
			};
		});

		setCouponInfo(couponInfo);
		setCouponList(couponData);
	};

	useLayoutEffect(() => {
		getCouponList();
		liffActions.setShowFullPageLoader(true);

		setTimeout(() => {
			liffActions.setShowFullPageLoader(false);
		}, 1000);
	}, [location]);

	return (
		<div className="cCouponPage h-screen w-full bg-gray-50">
			<img className="background" src={Background}></img>
			<div className="top-bar">
				<div className="title">
					<span>我的優惠券</span>
				</div>
				<div className="button-list">
					<button
						className={`${modeFilter === "offline" ? "active" : ""}`}
						onClick={() => setModeFilter("offline")}
					>
						<span>門市券 ({couponInfo["offline"].total})</span>
					</button>
					<button
						className={`${modeFilter === "online" ? "active" : ""}`}
						onClick={() => setModeFilter("online")}
					>
						<span>線上券 ({couponInfo["online"].total})</span>
					</button>
				</div>
			</div>
			<div className="coupon-container">
				<div className="coupon-state">
					<button
						className={`${stateFilter === CouponState.UNUSED ? "active" : ""}`}
						onClick={() => setStateFilter(CouponState.UNUSED)}
					>
						<span>未使用 {couponInfo[modeFilter]?.unusedCouponCount}</span>
					</button>
					<button
						className={`${stateFilter === CouponState.USED ? "active" : ""}`}
						onClick={() => setStateFilter(CouponState.USED)}
					>
						<span>已使用 {couponInfo[modeFilter]?.usedCouponCount}</span>
					</button>
					<button
						className={`${stateFilter === CouponState.EXPIRED ? "active" : ""}`}
						onClick={() => setStateFilter(CouponState.EXPIRED)}
					>
						<span>已過期 {couponInfo[modeFilter]?.expiredCouponCount}</span>
					</button>
				</div>
				<div className="coupon-list">
					{filteredCouponList.map((coupon, index) => (
						<Coupon key={index} coupon={coupon} isExpired={stateFilter === CouponState.EXPIRED} />
					))}
				</div>
			</div>
		</div>
	);
};

export { CouponPage, CouponState, CouponType };
