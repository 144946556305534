/* eslint-disable no-unused-vars */
import {
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from "react-router-dom";
import { LiffLayout, LiffLoginGuard } from "@/apps/liff/layout";
import {
	AuthPage,
	HomePage,
	CouponPage,
	GiftDeliveryPage,
	ProfilePage,
	AgreementPage,
	PhoneBindingPage,
	RedeemPage,
	PointHistoryPage,
	OrderHistoryPage,
	BindingSuccessPage,
	RedemptionIntroPage,
	ExchangeHistoryPage,
	ExchangeDetailPage,
	NotFoundPage,
	ActivityPage,
	MgmFirst,
	Mission2_4
} from "@/apps/liff/pages";
import {
	LoginPage,
	ExchangeListPage,
	ProductListPage,
	ExchangeListDetailPage,
	ProductListDetailPage,
	ImgCarouselListPage,
	TextCarouselListPage,
	PopupListPage,
	PopupLisDetailPage,
	ImgCarouselListDetailPage,
	TextCarouselListDetailPage,
	CouponConsumeListPage,
	CouponConsumeDetailPage,
} from "@/apps/admin/pages";
import { AdminLayout, AdminLoginGuard } from "@/apps/admin/layout";
import { LiffProvider } from "@/utils";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { LiffLoader } from "@/components";
import ReactGA from 'react-ga4';

function App() {
	const routes = createRoutesFromElements(
		<Route path="/">
			<Route path="/admin">
				<Route index element={<LoginPage />}></Route>
				<Route element={<AdminLoginGuard />}>
					<Route element={<AdminLayout />}>
						<Route path="productList" element={<ProductListPage />}></Route>
						<Route
							path="productList/add"
							element={<ProductListDetailPage />}
						></Route>
						<Route
							path="productList/edit/:productId"
							element={<ProductListDetailPage />}
						></Route>
						<Route path="exchangeList" element={<ExchangeListPage />}></Route>
						<Route
							path="exchangeList/edit/:refId"
							element={<ExchangeListDetailPage />}
						></Route>
						<Route path="couponConsumeList" element={<CouponConsumeListPage />}></Route>
						<Route
							path="couponConsumeList/edit/:recordId"
							element={<CouponConsumeDetailPage />}
						></Route>
						<Route
							path="displayManagement/imgCarouselList"
							element={<ImgCarouselListPage />}
						></Route>
						<Route
							path="displayManagement/imgCarouselList/add"
							element={<ImgCarouselListDetailPage />}
						></Route>
						<Route
							path="displayManagement/imgCarouselList/edit/:bannerId"
							element={<ImgCarouselListDetailPage />}
						></Route>
						<Route
							path="displayManagement/textCarouselList"
							element={<TextCarouselListPage />}
						></Route>
						<Route
							path="displayManagement/textCarouselList/add"
							element={<TextCarouselListDetailPage />}
						></Route>
						<Route
							path="displayManagement/textCarouselList/edit/:bannerId"
							element={<TextCarouselListDetailPage />}
						></Route>
						<Route
							path="displayManagement/popupList"
							element={<PopupListPage />}
						></Route>
						<Route
							path="displayManagement/popupList/add"
							element={<PopupLisDetailPage />}
						></Route>
						<Route
							path="displayManagement/popupList/edit/:popupId"
							element={<PopupLisDetailPage />}
						></Route>
					</Route>
				</Route>

				<Route path="*" element={<LoginPage />}></Route>
			</Route>

			<Route path="/auth" element={<AuthPage />} />
			<Route path="/" element={<LiffLoginGuard />}>
				<Route path="agreement" element={<AgreementPage />} />
				<Route path="binding" element={<PhoneBindingPage />} />
				<Route path="bindingSuccess" element={<BindingSuccessPage />} />
				<Route path="profile" element={<ProfilePage />} />
				<Route element={<LiffLayout />}>
					<Route index element={<HomePage />} />
					<Route path="coupon" element={<CouponPage />} />
					<Route path="coupon/gift-delivery" element={<GiftDeliveryPage />} />
					<Route path="redeem" element={<RedeemPage />} />
					<Route path="redeem/:productId" element={<RedemptionIntroPage />} />
					<Route path="exchange" element={<ExchangeHistoryPage />}></Route>
					<Route
						path="exchange/:productId"
						element={<ExchangeDetailPage />}
					></Route>
					<Route path="points" element={<PointHistoryPage />} />
					<Route path="orders" element={<OrderHistoryPage />} />
					<Route path="activity" element={<ActivityPage />}></Route>
					<Route path="activity/:slug" element={<MgmFirst />}></Route>
					<Route path="activity/mission/2-4" element={<Mission2_4 />}></Route>
				</Route>

				<Route path="*" element={<NotFoundPage />}></Route>
			</Route>
		</Route>,
	);

	const router = createBrowserRouter(routes);

	// 禁止雙指縮放
	const preventMultiTouchZoom = () => {
		const preventMultiTouch = (event) => {
			if (event.touches.length >= 2) {
				event.preventDefault();
			}
		};

		// 添加事件監聽器
		document.addEventListener("touchstart", preventMultiTouch, {
			passive: false,
		});
		document.addEventListener("touchmove", preventMultiTouch, {
			passive: false,
		});
		document.addEventListener("touchend", preventMultiTouch, {
			passive: false,
		});

		// 返回一個清理函數來移除監聽器
		return () => {
			document.removeEventListener("touchstart", preventMultiTouch);
			document.removeEventListener("touchmove", preventMultiTouch);
			document.removeEventListener("touchend", preventMultiTouch);
		};
	};

	useEffect(() => {
		preventMultiTouchZoom();
		ReactGA.initialize(process.env.REACT_APP_GA_ID);
	}, []);

	return (
		<LiffProvider>
			<div className="App">
				<RouterProvider router={router}></RouterProvider>
			</div>
			<LiffLoader />
			<Toaster position="top-center" reverseOrder={false} />
		</LiffProvider>
	);
}

export default App;
