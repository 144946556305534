export default class AppError {
	static NO_ERROR = 0;

	// Common
	static INVALID_REQUEST = 1;
	static INVALID_FORMAT = 2;
	static ALREADY_EXIST = 3;
	static NO_FOUND = 4;
	static PERMISSION_DENIED = 5;
	static INVALID_SOURCE_ID = 6;
	static RETRY_REQUEST = 7;
	static INVALID_STATUS = 8;
	static INVALID_ROLE = 9;
	static INVALID_TOKEN = 10;
	static INVALID_TOKEN_PAIRN = 11;
	static INVALID_API_LOGIN_TOKEN = 12;
	static INVALID_ROUTE = 13;
	static IMAGE_SIZE_ERROR = 14;

	// Database
	static DB_UNHANDLED_EXCEPTION = 11;
	static DB_DUPLICATED_ENTRY = 12;

	//Member
	static PASSWORD_CHANGED_REQUIRED = 601;
	static PASSWORD_EXPIRED = 602;
	static ACCOUNT_IS_INACTIVE = 603;
	static PASSWORD_FORMAT_ERROR = 604;
	static INVALID_USER_OR_PASSWORD = 605;
	static INVALID_PASSWORD = 606;
	static INVALID_VERIFICATION_CODE = 607;
	static VERIFICATION_CODE_IN_COOLDOWN = 608;
	static VERIFICATION_CODE_EXPIRED = 609;
	static VERIFICATION_CODE_FAILED_TOO_MANY_TIMES = 610;
	static VERIFICATION_CODE_CANNOT_SEND = 611;
	static LOGIN_FAILED_COUNT_LIMIT = 612;
	static PASSWORD_LOGIN_IS_DISABLED = 613;
	static GET_LINE_ACCESS_TOKEN_FAILED = 614;
	static MOBILE_USED = 615;
	static INVALID_MOBILE = 616;
	static ALREADY_RESERVED = 617;

	// CRM
	static CRM_FAILED = 701;
	static MAAC_FAILED = 702;
	static CRM_COUPON_CONSUME_FAILED = 703;
	static CRM_COUPON_INVALID_DATE = 704;
	static CRM_COUPON_ALREADY_CONSUMED = 705;
	static CRM_COUPON_EXPIRED = 706;

	// Activity
	static ACTIVITY_IS_FULL = 801;

	// Exchange Product
	static EXCHANGE_IS_FULL = 901;
	static THE_EXCHANGE_QUOTA_FOR_ONE_USER_IS_FULL = 902;
	static EXCHANGE_DATE_ERROR = 903;
	static FIND_EXCHANGE_REF_ID_ERROR = 904;
	static NOT_ENOUGH_POINTS = 905;
	static CRM_POINT_REDEEM_ERROR_CODE = 906;
	static QUANTITY_LESS_THAN_EXCHANGE_COUNT = 908;
	static STOCK_LESS_THAN_EXCHANGE_COUNT = 909;
	static EXCHANGE_SAME_SKU_ID = 910;
	static NOT_ENOUGH_TIER = 911;
	static INVALID_TIER = 912;
}
