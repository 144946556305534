import { useContext } from "react";
import { LiffContext } from "@/utils";
import { TierStyle } from "@/utils/tierStyles";

const useMemberTier = () => {
	// reducer
	const { liffState } = useContext(LiffContext);
	const { tierCode, amountToNextTier } = liffState.memberData;
	const currentTier = new TierStyle().getTierStyleByCode(tierCode);

	// 升級所需的剩餘金額
	const nextTierGap = amountToNextTier
		? Math.floor(parseInt(amountToNextTier, 10))
		: 0;

	// 下一個等級所需的金額
	const nextTierThreshold = Math.floor(currentTier.nextTierThreshold || 1);

	// 消費金額進度條的值
	let progress = ((nextTierThreshold - nextTierGap) / nextTierThreshold) * 100;
	progress = Math.min(Math.max(progress, 0), 100);

	const renderMemberTierInfo = () => {
		let nextTierName = "";

		switch (tierCode) {
			case "EX":
				nextTierName = "開拓者 Blazer";
				break;
			case "BL":
				nextTierName = "冒險家 Adventurer";
				break;
			case "AT":
				nextTierName = "巔峰客 Pioneer";
				break;
			case "PI":
				nextTierName = "巔峰摯友 Team Summit";
				break;
			case "TS":
				return `FACE THE NORTH FACE, MEET UP AT THE SUMMITS`;
			default:
				return null;
		}

		if (tierCode === "EX" && nextTierGap < 1) {
			return `消費任意金額即可升級為 ${nextTierName}！`;
		}

		if (tierCode === "PI") {
			return `再消費 ${nextTierGap.toLocaleString()} 即有機會獲得品牌邀請成為 ${nextTierName}！`;
		}

		return `再消費 ${nextTierGap.toLocaleString()} 即可升級為 ${nextTierName}！`;
	};

	return { progress, renderMemberTierInfo };
};

export default useMemberTier;
