import { useEffect, useState, useContext, useRef } from "react";
import { LiffContext, api } from "@/utils";
import { ReactComponent as IconTruck } from "@/assets/icon/liff/order/icon-truck.svg";
import { ReactComponent as IconStore } from "@/assets/icon/liff/order/icon-store.svg";
import { ReactComponent as IconArrowUp } from "@/assets/icon/liff/order/icon-arrow-up.svg";
import { ReactComponent as IconArrowDown } from "@/assets/icon/liff/order/icon-arrow-down.svg";
import bgImage from "@/assets/img/liff/order/bg.png";

const ORDER_SOURCE = {
	OFFICIAL_WEBSITE: 100000027,
	STORE: 100000028,
	_91APP: 100000029,
	LINE: 100003002,
};

const formatPrice = (price) => {
	if (price && typeof price.toLocaleString === 'function') {
		return `$ ${price.toLocaleString()}`;
	} else {
		return '$ 0';
	}
};

const OrderRow = ({ label, value, className }) => {
	return (
		<div className={`flex justify-between py-[3px] ${className}`}>
			<div className="min-w-[30%] break-all">{label}</div>
			<div className="break-all">{value}</div>
		</div>
	);
};

const OrderCard = ({ order, expandedOrderCodes, onClickExpand }) => {
	const getOnlineOffline = (src) => {
		if (src === ORDER_SOURCE.STORE) {
			return <div className="flex items-center"><IconStore className="w-6 h-6 mr-1" />門市訂單</div>;
		} else {
			return <div className="flex items-center"><IconTruck className="w-6 h-6 mr-1" />線上訂單</div>;
		}
	};

	const getSourceRow = () => {
		if (order.source === ORDER_SOURCE.OFFICIAL_WEBSITE) return <OrderRow label="訂購來源" value="官網" />;
		if (order.source === ORDER_SOURCE.STORE) return <OrderRow label="購買門市" value={order.storeName} />;
		if (order.source === ORDER_SOURCE._91APP) return <OrderRow label="訂購來源" value="APP" />;
		if (order.source === ORDER_SOURCE.LINE) return <OrderRow label="訂購來源" value="LINE" />;
		return "其他";
	};

	const getOrderStatus = (direction) => {
		if (direction === 100000000) return <span className="text-[#000000]">訂單狀態：完成</span>;
		if (direction === 100000001) return <span className="text-[#BB1A2C]">訂單狀態：退貨</span>;
		if (direction === 100000002) return <span className="text-[#1797FF]">訂單狀態：換貨</span>;
		return "其他";
	};

	return (
		<div className="mb-3 p-2 rounded-md bg-white text-xs">
			<div className="flex justify-between mb-[5px] text-sm">
				<div>{getOnlineOffline(order.source)}</div>
				<div>{getOrderStatus(order.direction)}</div>
			</div>
			<OrderRow label="訂單/交易編號" value={order.orderCode} />
			<OrderRow label="訂購日期" value={order.orderDate} />
			{getSourceRow()}
			<div className="border-b-[1px] border-b-gray-300 my-[5px]"></div>
			<OrderRow label="應付金額" value={formatPrice(order.totalAmount)} />
			<OrderRow label="折扣金額" value={formatPrice(order.totalDisAmount)} />
			<OrderRow label="實付金額" value={formatPrice(order.totalAmount - order.totalDisAmount)} className="text-[#BB1A2C]" />
			<div className="mt-5 mb-2">
				<button className="flex items-center justify-center mx-auto px-4 py-2 rounded-md bg-black text-white text-xs" onClick={onClickExpand}>
				查看訂單明細
				{expandedOrderCodes[order.orderCode] === true ? <IconArrowUp className="w-2.5 h-2.5 ml-1" /> : <IconArrowDown className="w-2.5 h-2.5 ml-1" />}
				</button>
			</div>
		</div>
	);
};

const ItemRow = ({ label, value, className }) => {
	return (
		<div className={`flex justify-between border-b-[1px] border-b-gray-300 py-[3px] ${className}`}>
			<div className="min-w-[30%] break-all">{label}</div>
			<div className="break-all">{value}</div>
		</div>
	);
};

const ItemCard = ({ item }) => {
	return (
		<div className="mb-3 rounded-md bg-white">
			<div className="p-2 text-sm bg-black text-white rounded-t-md">
				商品編碼：{item.sku}
			</div>
			<div className="px-2 py-4 text-xs">
				<ItemRow label="商品名稱" value={item.name} />
				<ItemRow label="單價" value={formatPrice(item.price)} />
				<ItemRow label="數量" value={item.qty} />
				<ItemRow label="應付金額" value={formatPrice(item.totalAmount)} />
				<ItemRow label="折扣金額" value={formatPrice(item.totalDisAmount)} />
				<ItemRow label="實付金額" value={formatPrice(item.totalAmount - item.totalDisAmount)} className="text-[#BB1A2C]" />
			</div>
		</div>
	);
};

const OrderHistoryPage = () => {
	const { liffActions } = useContext(LiffContext);
	const [selectedTab, setSelectedTab] = useState("online");
	const isFirstLoad = useRef(true);
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState([]);
	const [pagination, setPagination] = useState({
		total: -1,
		page: 1,
		pageSize: 50,
	});
	const totalPages = pagination.total !== -1 ? Math.ceil(pagination.total / pagination.pageSize) : -1;
	const loadMoreRef = useRef(null);
	const [expandedOrderCodes, setExpandedOrderCodes] = useState({});

	const getOrders = async () => {
		try {
			isFirstLoad.current && liffActions.setShowFullPageLoader(true);
			const res = await api(1).get("/get_crm_member_order_list", {
				params: { page: pagination.page, pageSize: pagination.pageSize },
			});
			setOrders(prev => [...prev, ...res.data.rows]);
			if (pagination.total === -1) {
				setPagination({
					...pagination,
					total: res.data.total,
				});
			}
		} catch (error) {
			console.error("Get Orders Failed", error);
		} finally {
			isFirstLoad.current && liffActions.setShowFullPageLoader(false);
			setLoading(false);
		}
	};

	const orderFilterCallback = (order) => {
		if (selectedTab === "store") return order.source === ORDER_SOURCE.STORE;
		if (selectedTab === "online") return order.source !== ORDER_SOURCE.STORE;
		return false;
	};

	useEffect(() => {
		const currentObserver = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && (totalPages !== -1 && pagination.page < totalPages) && !loading) {
					// Intent to load next page.
					setLoading(true); // Put here to prevent multiple requests.
					setPagination(prev => ({ ...prev, page: prev.page + 1 }));
				}
			},
			{ threshold: 0.5 }
		);

		if (loadMoreRef.current) {
			currentObserver.observe(loadMoreRef.current);
		}

		return () => {
			if (loadMoreRef.current) {
				currentObserver.unobserve(loadMoreRef.current);
			}
		};
	}, [pagination.page, totalPages, loading]);

	useEffect(() => {
		if (pagination.page > 1) {
			getOrders();
		}
	}, [pagination.page]);

	useEffect(() => {
		const init = async () => {
			if (isFirstLoad.current) {
				await getOrders();
				isFirstLoad.current = false;
			}
		};
		init();
	}, []);

	return (
		<div className="min-h-screen bg-center bg-cover bg-no-repeat bg-fixed" style={{ backgroundImage: `url(${bgImage})` }}>
			<div className="pt-8 pb-5 text-center bg-white">
				<h1 className="text-xl font-bold">消費紀錄查詢</h1>
			</div>
			<div className="px-[2vw] flex justify-center bg-white">
				<div
					className={`w-1/2 text-center border-r-[1px] border-r-gray-300 ${selectedTab === "online" ? "border-b-2 border-black" : ""}`}
					onClick={() => setSelectedTab("online")}
				>
					線上訂單
				</div>
				<div
					className={`w-1/2 text-center ${selectedTab === "store" ? "border-b-2 border-black" : ""}`}
					onClick={() => setSelectedTab("store")}
				>
					門市訂單
				</div>
			</div>
			<div className="mb-14 p-2">
				{orders.filter(orderFilterCallback).map((order) => (
					<div key={order.orderCode}>
						<OrderCard order={order} expandedOrderCodes={expandedOrderCodes} onClickExpand={() => setExpandedOrderCodes((prev) => ({ ...prev, [order.orderCode]: !prev[order.orderCode] }))} />
						{expandedOrderCodes[order.orderCode] === true && order.details.map((item, index) => (
							<ItemCard key={index} item={item} />
						))}
					</div>
				))}
				<div ref={loadMoreRef} className="h-4 mb-4">
					{loading && <div className="flex justify-center items-center text-sm text-gray-300">Loading...</div>}
				</div>
			</div>
		</div>
	);
};

export default OrderHistoryPage;
